import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';

type StyledInputProps = {
    isActive: boolean;
    withLabel: boolean;
};

type StyledInputFieldWrapperProps = {
    isValid: boolean;
    disabled?: boolean;
};

type StyledInputFieldProps = {
    trailingIcon: boolean;
    isValid: boolean;
    hasFocus: boolean;
};

export const StyledInput = styled.input<StyledInputProps>(
    ({ theme }) => ({
        border: 'none',
        width: '100%',
        boxSizing: 'border-box',
        textAlign: 'left',
        display: 'block',
        fontSize: theme.fontSizes.lg,
        outline: 'none',
        padding: 0,
        textOverflow: 'ellipsis',
        '&::placeholder': {
            transition: '0.1s color',
            color: theme.colors.light50,
            textOverflow: 'ellipsis',
        },
        '&:disabled': {
            background: 'none',
        },
    }),
    ifProp(
        { withLabel: true, isActive: false },
        {
            [`&::placeholder`]: {
                color: 'transparent',
            },
        }
    )
);

export const StyledInputField = styled.div<StyledInputFieldProps>(
    ({ theme, isValid, trailingIcon }) => {
        const borderColor = isValid ? theme.colors.formFieldText : theme.colors.error;

        return {
            height: 56,
            backgroundColor: theme.colors.formFieldBackground,
            border: theme.borders.border,
            borderColor: borderColor,
            lineHeight: theme.lineHeights.single,
            position: 'relative',
            flex: '1 1 auto',
            display: 'flex',
            cursor: 'type',
            padding: `${theme.spaces[1]} ${trailingIcon ? 0 : theme.spaces[3]} ${theme.spaces[1]} ${
                theme.spaces[3]
            }`,
        };
    },
    ifProp('hasFocus', ({ theme, isValid }) => ({
        boxShadow: `inset 0px -2px 0px ${
            isValid ? theme.colors.formFieldActiveBoxshadow : theme.colors.error
        }`,
    }))
);

export const StyledInputFieldWrapper = styled.div<StyledInputFieldWrapperProps>(
    ({ theme, disabled, isValid }) => ({
        position: 'relative',
        opacity: disabled ? theme.opacity.disabled : 1,
        color: isValid ? theme.colors.formFieldActiveText : theme.colors.error,
        display: 'flex',
    })
);

export const StyledIcon = styled.span<{ disabled?: boolean; leading?: boolean }>(
    ({ disabled, leading }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // If leading we use the container padding
        paddingLeft: leading ? 0 : 16,
        paddingRight: leading ? 16 : 24,

        '*': {
            cursor: 'pointer',
            pointerEvents: disabled ? 'none' : 'all',
            height: 24,
            width: 24,
        },
    })
);

export const StyledContentWrapper = styled.div(() => ({
    position: 'relative',
    display: 'flex',
    alignContent: 'center',
    width: '100%',
}));
