import React, { useImperativeHandle, useRef, useState } from 'react';
import { useInputField } from '../../hooks/useInputField';
import {
    StyledInputField,
    StyledInputFieldWrapper,
    StyledInput,
    StyledIcon,
    StyledContentWrapper,
} from './styled';
import { StyledInvalidMessage } from '../InvalidMessage';
import { StyledHelpText } from '../HelpText';
import { Label } from '~/shared/components';

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

export type InputFieldProps = Omit<InputProps, 'value'> & {
    /**
     * Adds a label to the input field. This is required for accessibilty.
     */
    label?: string;

    /**
     * Add an additional help text below the input field.
     */
    helpText?: string;

    /**
     * Add an additional help text below the input field.
     */
    invalidMessage?: string;

    /**
     * Set styling to indicate input is invalid.
     * Also shows the `invalidMessage` if provided
     */
    isInvalid?: boolean;

    /**
     * This component is uncontrolled - you will need to controll the value.
     */
    value?: string;

    /**
     * When active the label will move up above the actual input field
     */
    isActive?: boolean;

    /**
     * The icon left of the input field
     */
    trailingIcon?: JSX.Element;

    /**
     * The icon right of the input field
     */
    leadingIcon?: JSX.Element;
};

export const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>(
    (
        {
            label,
            helpText,
            invalidMessage,
            id,
            children,
            isActive,
            isInvalid,
            leadingIcon,
            trailingIcon,
            ...rest
        },
        ref
    ) => {
        const [isValidityValid, setIsValidityValid] = useState(true);
        const [hasFocus, setHasFocus] = useState(false);
        const inputRef = useRef<HTMLInputElement>(null);
        const inputFieldRef = useRef<HTMLDivElement>(null);

        const {
            fieldId,
            labelId,
            helpTextId,
            invalidMessageId,
            describedById,
            showHelpText,
            showInvalidMessage,
        } = useInputField({
            id,
            helpText,
            isInvalid,
            invalidMessage,
        });

        const { value, defaultValue } = rest;

        const hasValue = value === undefined ? !!defaultValue : !!value;
        const isInputActive = isActive || hasFocus || hasValue;
        const isValid = !showInvalidMessage && isValidityValid;

        const onFocusHandler = () => setHasFocus(true);
        const onBlurHandler = () => {
            setHasFocus(false);
            setIsValidityValid(inputRef.current?.validity.valid ? true : false);
        };

        const onInputFieldClick = (event: React.MouseEvent) => {
            if (inputRef.current !== event.target) {
                inputRef.current?.focus();
                inputRef.current?.click();
                event?.preventDefault();
                event?.stopPropagation();
            }
        };

        useImperativeHandle(ref, () => inputRef.current as HTMLInputElement, []);

        return (
            <div>
                <StyledInputFieldWrapper
                    key={fieldId}
                    onFocus={onFocusHandler}
                    onBlur={onBlurHandler}
                    isValid={isValid}
                    disabled={rest.disabled}
                >
                    <StyledInputField
                        onMouseDown={onInputFieldClick}
                        ref={inputFieldRef}
                        isValid={isValid}
                        trailingIcon={!!trailingIcon}
                        hasFocus={hasFocus}
                    >
                        <StyledContentWrapper>
                            {label ? (
                                <Label
                                    id={labelId}
                                    htmlFor={fieldId}
                                    title={label}
                                    children={label}
                                    isActive={isInputActive || !!leadingIcon}
                                    isValid={isValid}
                                    hasFocus={hasFocus}
                                />
                            ) : null}

                            {leadingIcon && (
                                <StyledIcon disabled={rest.disabled} leading>
                                    {leadingIcon}
                                </StyledIcon>
                            )}

                            <StyledInput
                                isActive={isInputActive}
                                id={fieldId}
                                aria-describedby={describedById}
                                ref={inputRef}
                                withLabel={!!label}
                                {...rest}
                            />
                        </StyledContentWrapper>

                        {trailingIcon && (
                            <StyledIcon disabled={rest.disabled}>{trailingIcon}</StyledIcon>
                        )}
                    </StyledInputField>
                    {children}
                </StyledInputFieldWrapper>
                {showInvalidMessage ? (
                    <StyledInvalidMessage id={invalidMessageId} children={invalidMessage} />
                ) : null}
                {showHelpText ? <StyledHelpText id={helpTextId} children={helpText} /> : null}
            </div>
        );
    }
);
