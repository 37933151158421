import { useFranchise } from '~/shared/utils';
import { useMemo } from 'react';
import { Image } from '~/shared/components';

const INTERNATIONAL_KEY = 'INT';

/**
 * Use this hook to get the delivery options for a country.
 * @param props
 * @example
 * const { options } = useDeliveryOptions({ countryCode: 'US' });
 * console.log(options);
 */
export const useCountryOptions = () => {
    const { activeMarket, franchiseMarket } = useFranchise();
    const deliveryOptions = useMemo(
        () =>
            franchiseMarket.markets
                ?.filter(({ countryKey }) => countryKey !== INTERNATIONAL_KEY)
                .map(({ countryName: title = '', countryKey: value = '' }) => ({
                    title,
                    value,
                    icon: (
                        // Wrap in span to prevent the flag from being stretched
                        <span>
                            <Image
                                src={`/assets/flags/${value.toLowerCase()}.png`}
                                height="20"
                                width="30"
                                alt={title ?? ''}
                                style={{ objectFit: 'contain' }}
                                skeletonShade="none"
                                unoptimized
                            />
                        </span>
                    ),
                })),
        [franchiseMarket]
    );

    const countryKey = activeMarket?.countryKey;
    const countryName = activeMarket?.countryName;

    // Don't ever use INT as a country key.
    const currentDeliveryKey = countryKey === INTERNATIONAL_KEY ? '' : countryKey;
    const currentCountryName = countryKey === INTERNATIONAL_KEY ? '' : countryName;
    const currentOption = useMemo(
        () => deliveryOptions?.find(({ value }) => value === currentDeliveryKey),
        [currentDeliveryKey, deliveryOptions]
    );

    return {
        deliveryOptions: deliveryOptions || [],
        currentDeliveryKey,
        currentCountryName,
        currentOption,
    };
};
